import { useEffect, useState, useContext } from "react";
import API from "../../../config/API";
import DataTable from "react-data-table-component";
import Loading from '../../theme/loading';
import {Link, useParams} from 'react-router-dom';
import './style.css';
import {EntornoGlobal} from '../../../config/globalContext';

const Dataset = () => {

    const pagination = 100;
    const [statecnx, setStateCnx] = useContext(EntornoGlobal);

    const [state, setState] = useState({
        state:"loading",
        search:"",
        job: null,
        iterations: null,
        loadJob: {
            request: +(new Date),
            state: null,
            jobToLoad: null
        },
        loadImage: {
            request: +(new Date),
            state: null,
            imageToLoad: null
        },
        selectCamera: null,
        selectOutput: null
    });

    const {datasetID, batchexecID} = useParams();

    // Main load
    useEffect(async ()=>{
      const dataset = await API.get('/v3/api/datasets/'+datasetID);
      const batchexec = await API.get(`/v3/api/datasets/${datasetID}/batchexecs/${batchexecID}`);
      const jobs_items = await API.get(`/v3/api/datasets/${datasetID}/batchexecs/${batchexecID}/jobs/status/pagination?page=0&limit=${pagination}`);
      const brief = await API.get(`/v3/api/datasets/${datasetID}/batchexecs/${batchexecID}/jobs/status/brief`);
      const jobs = jobs_items.items;
      const totalJobs = jobs_items.total;
      const statesJob = getStateJobs(jobs);
      const actived = { waiting: true, queued: true, paused: true, running: true, failed: true, finished: true };
      setState({...state, iterations: brief.total_iterations, totalJobs: brief.total_jobs, batchexec, dataset, jobs, totalJobs, page: 0, statesJob, state:"loaded", actived});
    },[]);

    // LoadJob
    useEffect(async()=>{
      if( state.loadJob.state !== null && state.loadJob.state !== "loaded" ){
        openJob(state.loadJob.jobToLoad);
      }
    },[state.loadJob.request]);

    // LoadImage
    useEffect(async()=>{
      if( state.selectCamera && state.selectOutput ){
        openImage(state.loadImage.imageToLoad);
      }
    },[state.loadImage.request]);

    const randomKey = () => Math.random().toString();

    const changeActived = (e) => {
      const obj = {...state};
      obj.actived[e] = !state.actived[e];
      setState(obj);
    }

    const getStateJobs = (jobs) => {
      const count = state => jobs.filter(e=>e.status === state).length;
      return {
        waiting: count('waiting'),
        finished: count('finished'),
        paused: count('paused'),
        queued: count('queued'),
        running: count('running'),
        failed: count('failed')
      }
    }

    const loadJob = async(job) => {
      setState({...state, job: null, loadJob: {
        request: +(new Date),
        state: "loading",
        jobToLoad: job
      }});
    }

    const openJob = async (job) => {

        const files = await API.get(`/v3/api/datasets/${datasetID}/batchexecs/${batchexecID}/jobs/${job}/files`);
        const info = await API.get(`/v3/api/datasets/${datasetID}/batchexecs/${batchexecID}/jobs/${job}`);
        const hasAnnotation = (files.filter(e=>e.endsWith(".json")).length ? true : false);
        let obj = {};
        // Cameras
        let cameras = new Set;
        files.forEach(e => {
            const camera = e.split("/")[4];
            cameras.add(camera)
        });
        cameras = [...cameras];

        if(state.selectCamera === null){ obj.selectCamera = cameras[0]; }

        // Outputs
        let outputs = new Set;
        files.forEach(file=>{
            const extension = file.split(".").pop();
            const name = file.split("/").pop().split(".")[0];
            if(["png","jpg","jpeg","exr","hdr"].includes(extension)){
                outputs.add(name);
            }
        });
        outputs = [...outputs];

        if(state.selectOutput === null){ obj.selectOutput = outputs[0]; }

        setState({
            ...state,
            job: {
                jobId: job,
                info,
                files,
                hasAnnotation,
                cameras,
                outputs
            },
            loadJob:{
                ...state.loadJob,
                state:"loaded"
            },
            loadImage:  {...state.loadImage, request: (+new Date())},
            ...obj
        });
    }

    const openImage = async () => {

      if( !state.selectCamera || !state.selectOutput  ){ return undefined; }
      try {
        const img = await API.get(`/v3/api/datasets/${datasetID}/batchexecs/${batchexecID}/jobs/${state.job.jobId}/getGif`, {chanel: state.selectOutput, framerate: 30, camera: state.selectCamera}, 'blob');
        setState({...state, job:{...state.job, image: URL.createObjectURL(img)}, loadImage:{...state.loadImage, state:"loaded"}});
      } catch (error) {
        setState({...state, loadImage:{...state.loadImage, state:"error"}});
      }


    //   const encode = encodeURIComponent( recoverFile() );
    //   const img = await API.get(`/v3/api/datasets/${datasetID}/files/${encode}/thumbnail`, {}, 'blob');

    }

    const recoverFile = () => {
      if( !state.selectCamera || !state.selectOutput  ){ return undefined; }

      const file = state.job.files.filter(f=>{
        const parts = f.split('/');
        if( parts[4] === state.selectCamera && parts[6].startsWith(state.selectOutput) ){ return true; }
      });

      return file[0];
    }

    const loadMoreJobs = async () => {
      if( state.jobs.length < state.totalJobs ){
        const nextPage = state.page + 1;
        const jobs_items = await API.get(`/v3/api/datasets/${datasetID}/batchexecs/${batchexecID}/jobs/status/pagination?page=${nextPage}&limit=${pagination}`);
        const newJobs = state.jobs.concat(jobs_items.items);
        const statesJob = getStateJobs(newJobs);
        setState({...state, jobs: newJobs, statesJob, page: nextPage})
     }
    }

    const changeParam = (param, value) => {
        const attr = {};
        if( param === "camera" ){ attr.selectCamera = value; }
        if( param === "output" ){ attr.selectOutput = value; }
        setState({...state,
            ...attr,
            loadImage:  {...state.loadImage, request: (+new Date())} }
            );
    }

    // THEME
    if(state.state !== "loaded"){ return <Loading></Loading> }

    return <div className="batchExecs">
        
            <h3 className="linkGreen greenanyverse">
                <Link to={"/app/datasets/"+datasetID}>{state.dataset.name}</Link> :  {state.batchexec.name}
            </h3>

        <div className="row mt-3">
            <div className="col-md-2 mt-2 text-small">
                <div>Iterations: {state.iterations}</div>
                <div>Captures: {state.totalJobs}</div>
            </div>
            <div className="col-md-7">

                <div className="row">
                    <div className="col-md-2 text-small"><span className="text-finished">■</span> Finished: {state?.statesJob?.finished}</div>
                    <div className="col-md-2 text-small"><span className="text-running">■</span> Running: {state?.statesJob?.running}</div>
                    <div className="col-md-2 text-small"><span className="text-queued">■</span> Queued: {state?.statesJob?.queued}</div>
                    <div className="col-md-2 text-small"><span className="text-paused">■</span> Paused: {state?.statesJob?.paused}</div>
                    <div className="col-md-2 text-small"><span className="text-failed">■</span> Failed: {state?.statesJob?.failed}</div>
                    <div className="col-md-2 text-small"><span className="text-waiting">■</span> Waiting: {state?.statesJob?.waiting}</div>
                </div>

                <div className="progress mb-3">
                    <div className="progress-bar item-finished" role="progressbar" style={{width: ((state.statesJob.finished*100)/state.totalJobs)+"%" }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                    <div className="progress-bar item-queued" role="progressbar" style={{width: ((state.statesJob.queued*100)/state.totalJobs)+"%" }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                    <div className="progress-bar item-paused" role="progressbar" style={{width: ((state.statesJob.paused*100)/state.totalJobs)+"%" }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                    <div className="progress-bar item-running" role="progressbar" style={{width: ((state.statesJob.running*100)/state.totalJobs)+"%" }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                    <div className="progress-bar item-failed" role="progressbar" style={{width: ((state.statesJob.failed*100)/state.totalJobs)+"%" }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                </div>

            </div>
            <div className="col-md-3">
                <div className="itemContainer flexend mt-2">
                    <span className="me-3">Filter: </span>
                    {
                        Object.keys(state.actived).map(e=>
                            <div key={randomKey()} onClick={()=>changeActived(e)} className={"itemJob item-"+e+(!state.actived[e]?"-border":"")}></div>
                        )
                    }
                </div>
            </div>
        </div>

        <div className="itemContainer mt-3">
            {
            Object.keys(state.actived).filter(e=>state.actived[e]).map(st => {
                return state.jobs.filter(jobstofilter=>jobstofilter.status===st).map(jobs=>{
                    return <div title={jobs.id} key={jobs.id} className={"itemJob item-"+jobs.status} onClick={jobs.status==="finished"?()=>loadJob(jobs.id):()=>{}}>{(jobs.progress===100?"":parseInt(jobs.progress))}</div>
                })
            })
            }
        </div>

        {
          ( state.jobs.length < state.totalJobs ) ?
            <div onClick={loadMoreJobs} className="pointer text-center">
                {state.jobs.length} of {state.totalJobs} <span className="fw-bold">&gt;</span>
            </div>:""
        }

        <div>
            { (state?.job === null) ? "" : 
            <div>
                <h5 className="mb-4 mt-3">Iteration {state.job.info.data.iterationIndex} - Capture {state.job.info.data.captureIndex}</h5>

                {/* Cameras */}
                <div>
                    <span className="grey text-large me-2">Cameras</span> {state.job.cameras.map( c =>
                        <button key={randomKey()} onClick={()=>{changeParam('camera', c)}} className={"btn btn-anyverse me-3 "+(c===state.selectCamera?"btn-anyverse-active":"") }>{c}</button>
                    )}
                </div>

                {/* Output */}
                <div className="mt-3">
                    <span className="grey text-large me-2">Outputs</span> {state.job.outputs.map( output =>
                        <button key={randomKey()} onClick={()=>{changeParam('output', output)}} className={"btn btn-anyverse me-3 "+(output===state.selectOutput?"btn-anyverse-active":"") }>{output}</button>
                    )}
                </div>

                <div className="row mt-3">
                    <div className="col-md-1"></div> 
                    <div className="col-md-9">
                      <div className="text-small greenanyverse2 text-center">Attention, the images you will see below are samples. To download them at maximum quality, use Anyverse Studio.</div>
                      <div className="mt-1 mb-5">
                        {
                          !state?.job?.image ? "" :
                            <img src={state.job.image} className="w-100" /> 
                        }
                        { state.loadImage.state ===" loading" ? <Loading/> : "" }
                        { 
                          state.loadImage.state === "error" ?
                            <div className="alert alert-danger">Error when try to render the image.</div> : 
                            ""
                        }
                      </div>


                    </div> 

                </div>

            </div>
            }

            {
                (state.loadJob.state==="loading"?<div className="mt-4"><Loading/></div>:"")
            }
        </div>
    </div>

}

export default Dataset;